import React,{useState} from "react";
import PidsDropdownOption from "./PidsDropdownOption/PidsDropdownOption";

import './PidsDropdown.css';

import tocJSON from '../../Data/toc.json';

const PidsDropdown = ({ labelText, selectedCapability, ALL_PRODUCTS_ID }) => {

    const [expanded, setExpanded] = useState(false);

    const dropdownClickHandler = (event) =>{
        event.preventDefault();
        setExpanded(!expanded);
    }

    const generatePidOptions = () => {
        const optionEls = [];
        optionEls.push(<PidsDropdownOption 
            key={'all-products'} 
            title={'All Categories'}
            id={ALL_PRODUCTS_ID}
            selected={selectedCapability === '' || selectedCapability === ALL_PRODUCTS_ID ? true : false}
            expanded={expanded}
            setExpanded={setExpanded}
        />)

        Object.keys(tocJSON).forEach((pid,idx) => {
            const pidObject = tocJSON[pid];
            if(selectedCapability === pid){
                optionEls.push(<PidsDropdownOption 
                                    key={pidObject.key} 
                                    title={pidObject.title}
                                    id={pidObject.key}
                                    selected={true}
                                    setExpanded={setExpanded}
                                />)
            }
            else{
                optionEls.push(<PidsDropdownOption 
                                    key={pidObject.key} 
                                    title={pidObject.title}
                                    id={pidObject.key}
                                    setExpanded={setExpanded}
                                />)
            }
        })

        return optionEls;
    }

    const getSelectedPidTitle = () => {
        if(selectedCapability === '' || selectedCapability === ALL_PRODUCTS_ID){
            return 'All Categories'
        }
        else{
            return tocJSON[selectedCapability].title
        }
    }

    return(
        <div className="dropdown-wrapper">
            <label className="dropdown-label" id="downshift-:r5:-label" htmlFor="downshift-:r5:-toggle-button">{ labelText }</label>
            <div className={["dropdown-container",expanded ? "list-box--expanded": ""].join(' ')}>
                <button className="list-box_field"
                    type="button"
                    aria-describedby="dropdown-helper" 
                    title="Option 1" 
                    aria-activedescendant="" 
                    aria-controls="downshift-:r5:-menu" 
                    aria-expanded="false" aria-haspopup="listbox" 
                    aria-labelledby="downshif" role="combobox" 
                    tabIndex="0"
                    onClick={dropdownClickHandler}
                >
                    <span className="selected_option_label">{getSelectedPidTitle()}</span>
                    <div className="cds--list-box__menu-icon">
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" name="chevron--down" aria-label="Open menu" width="16" height="16" viewBox="0 0 16 16" role="img" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
                            <title>Open menu</title>
                        </svg>
                    </div>
                </button>
                <ul className="list-box__menu">
                    {generatePidOptions()}
                </ul>
            </div>
        </div>
    )
}

export default PidsDropdown;