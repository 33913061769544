import React,{ useState, useEffect } from "react";
import { useParams } from 'react-router-dom'

import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import NavBar from "../../Components/NavBar/NavBar";
import ProductTitle from "../../Components/ProductTitle/ProductTitle";
import ProductContent from "../../Components/ProductContent/ProductContent";
import Feedback from "../../Components/Feedback/Feedback";
import Footer from "../../Components/Footer/Footer";

import './ProductLandingPage.css';

import productJSON from '../../Data/products.json';
import capabilityJSON from '../../Data/capabilities.json';
import tocJSON from '../../Data/toc.json';

const ProductLandingPage = ({selectedCapability}) => {
    const params = useParams();
    const [selectedProduct, setSelectedProduct] = useState('');
    const deploymentLabels = {'On-premises':'Software', 'SaaS': 'SaaS'};

    useEffect(() => {
        window.scrollTo(0,0);

        const currentURL = `/${params.deployment}/${params.productName}`;
        const productCode = Object.keys(productJSON).filter(idx => productJSON[idx].baseURL === currentURL);
        
        if(productCode && productCode.length){
            setSelectedProduct(productCode[0])
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getParentToc = () => {
        console.log('found:', selectedCapability);
        if(selectedCapability){
            return {"text": tocJSON[selectedCapability].title, "link":`/#${tocJSON[selectedCapability].key}`};
        }
        else if(selectedProduct){
            const parent_tid = Object.keys(tocJSON).filter(tid => tocJSON[tid]["capability_mapping"].filter(cid => productJSON[selectedProduct].capabilityId.includes(cid)).length)[0];
            if(parent_tid){
                return {"text": tocJSON[parent_tid].title, "link":`/#${tocJSON[parent_tid].key}`};
            }
            else{
                return {};
            }
        }

        return {};
    }

    return(
        <React.Fragment>
            <NavBar/>
            {
                selectedProduct ?
                <Breadcrumbs
                    data={[
                        {"text":"Home", "link":"/"},
                        {...getParentToc()},
                        {"text":deploymentLabels[productJSON[selectedProduct].deployment], "link":`/#${capabilityJSON[productJSON[selectedProduct].capabilityId].key}`},
                        {"text":productJSON[selectedProduct].title, "link":productJSON[selectedProduct]['product_card_link']}
                    ]}
                />:
                ''
            }
            <section className="product-landing-wrapper">
                <ProductTitle selectedProduct={selectedProduct}/>
                <ProductContent selectedProduct={selectedProduct} baseURL={selectedProduct in productJSON ? productJSON[selectedProduct].baseURL : ''}/>
            </section>
            <Feedback />
            <Footer/>
        </React.Fragment>
    )
}

export default ProductLandingPage;