import React,{ useEffect, useRef, useState } from "react";

import SearchBox from "../SearchBox/SearchBox";
import NavBar from '../../Components/NavBar/NavBar';

import './Banner.css';
import bannerImage from '../../assets/images/background.png';
import logo from './ibm_light.svg';
import logoDark from '../../assets/images/ibm_dark.svg';

const Banner = () => {

    const bannerContainerRef = useRef();
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll',() => {
            setScrollPosition(window.scrollY);
        })
    },[])

    return(
        <React.Fragment>
            <div id="landing-banner" className="large" ref={bannerContainerRef}>
                    <div id="banner-image-container" style={{backgroundImage:`url(${bannerImage})`}}>
                        <img src={logo} alt="IBM Logo" id="landing-banner-logo" />
                    </div>
                    <div id="logo-container">
                        <img src={logoDark} alt="IBM webMethods Documentation"/>
                    </div>
                    <div id="landing-banner-header">
                        <h1 id="landing-banner-title">IBM webMethods Documentation</h1>
                        <div id="landing-banner-searchbox">
                            <div id="landing-banner-gsc-search">
                                {
                                    scrollPosition <= 300 ?
                                        <React.Fragment>
                                            <style>
                                                {
                                                    `
                                                        /* Auto-complete suggestions styling */
                                                        table.gssb_c {
                                                            top: 237px !important;
                                                            left: 50% !important;
                                                            transform: translateX(-50%) !important;
                                                            width: 70vw !important;
                                                            max-width: 610px;
                                                            min-width: 229px;
                                                            -webkit-transform: translateX(-50%) !important;
                                                            -moz-transform: translateX(-50%) !important;
                                                            -ms-transform: translateX(-50%) !important;
                                                            -o-transform: translateX(-50%) !important;
                                                        }

                                                        @media screen and (max-width: 771px){
                                                            table.gstl_50.gssb_c {
                                                                top: 253px !important;
                                                            }
                                                        }

                                                        @media screen and (max-width: 679px){
                                                            table.gstl_50.gssb_c {
                                                                top: 272px !important;
                                                            }
                                                        }

                                                        @media screen and (max-width:356px) {
                                                            table.gstl_50.gssb_c {
                                                                top: 291px !important;
                                                            }
                                                        }

                                                        /* small banner styling */

                                                        /* Auto-complete suggestions styling */
                                                        .navbar-visible table.gssb_c {
                                                            min-width: 160px !important;
                                                            width: 40vw !important;
                                                            max-width: 360px !important;
                                                            margin-left: -4px;
                                                        }

                                                        @media screen and (max-width: 470px) {
                                                            .navbar-visible table.gssb_c {
                                                                width: 90% !important;
                                                                max-width: 360px !important;
                                                            }
                                                        }
                                                    `
                                                }
                                            </style>
                                            <SearchBox/>
                                        </React.Fragment>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
            </div>
            {
                scrollPosition > 300 ?
                <NavBar/>
                : ''
            }
        </React.Fragment>
    )
}

export default Banner;
