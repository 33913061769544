import React,{useState, useEffect} from "react";

import TopicCard from "../TopicCard/TopicCard";
import VersionDropdown from "../VersionDropdown/VersionDropdown";
import DocumentCategoryDropdown from '../DocumentCategoryDropdown/DocumentCategoryDropdown';

import './ProductContent.css';
import productJSON from '../../Data/products.json';
import topicsJSON from '../../Data/topics.json';
import topicCategoriesJSON from '../../Data/topic-categories.json';

const ProductContent = ({ selectedProduct, baseURL }) => {

    // Data state variables
    // eslint-disable-next-line no-unused-vars
    const [categoriesData, setCategoriesData] = useState(topicCategoriesJSON);
    const [topicData, setTopicData] = useState([]);

    // Filter state variables
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [urlHash, setUrlHash] = useState('');

    const ALL_DOCUMENTS_KEY = 'all';

    // Function to add a hashchange event listener and
    // update the urHash state on page's first load
    useEffect(() => {
        window.addEventListener('hashchange',() => {
            const newHash = window.location.hash.split('#')[1];
            setUrlHash(newHash);
        })

        const newHash = window.location.hash.split('#')[1];
        if(newHash){
            setUrlHash(newHash);
        }

        if(selectedProduct in topicsJSON){
            setTopicData(productJSON[selectedProduct]['content']);
            setSelectedCategory(productJSON[selectedProduct]['active_topic_categories'][0]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(urlHash){
            let categoryId = Object.keys(categoriesData).find(idx => categoriesData[idx].key === urlHash);
            if(categoryId){
                setSelectedCategory(categoryId ? categoryId : productJSON[selectedProduct]['active_topic_categories'][0]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlHash]);

    const generateTocItems = () => {
        const tocEls = [];
        const category = selectedCategory ? selectedCategory : (selectedProduct ? productJSON[selectedProduct]['active_topic_categories'][0] : '');

        if(selectedProduct in productJSON){

            productJSON[selectedProduct]['active_topic_categories'].forEach((categoryKey, idx) => {
                const categoryObject = topicCategoriesJSON[categoryKey];
                tocEls.push(<span 
                                className={["topics-toc-item", (category === String(categoryObject.ID) ? 'active':'') ].join(' ')} 
                                id={[categoryObject.ID,'-toc-item'].join('')} 
                                category-id={categoryObject.ID}
                                key={`toc-item-${categoryObject.ID}`}
                                onClick={() => window.location.href = `#${categoryObject.key}`}
                            >
                                {categoryObject.title}
                            </span>)
            })

        }

        return tocEls;
    }

    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    useEffect(() => {
        const filteredTopicList = [];
        const contentData = selectedProduct in productJSON ? productJSON[selectedProduct]['content'] : [];
        const category = selectedCategory ? selectedCategory : (selectedProduct ? productJSON[selectedProduct]['active_topic_categories'][0] : '');
        const tocSelectedTopics = category in contentData ? contentData[category] : [];
        
        tocSelectedTopics.forEach(topicKey => {
            let topicObj = topicsJSON[topicKey];
            let searchValid = true;

            searchValue.split(' ').forEach(searchTerm => {
                const escapedSearchTerm = escapeRegex(searchTerm);
                if(!Boolean(topicObj.title.match(new RegExp(escapedSearchTerm,'ig')))){
                    searchValid = searchValid && false;
                }
            })

            if(searchValid){
                filteredTopicList.push(topicKey);
            }
        });

        setTopicData([...filteredTopicList]);

    },[selectedCategory,searchValue, selectedProduct]);

    const generateTopicCardElts = () => {
        const topicCardList = [];

        topicData.forEach(id => {
            const topicObj = topicsJSON[id];
            topicCardList.push(<TopicCard 
                key={`document-card-${id}`} 
                topicObj={topicObj}
                parentProduct={selectedProduct}
            />);
        });

        if(topicCardList.length === 0){
            topicCardList.push(
                <span key="no-topics-found" style={{'fontStyle':'italic','color':'var(--text-secondary,#525252)'}}>No topics found. Try altering filter selections</span>
            )
        }

        return [...topicCardList];
    }
    
    return(
        <section id="content-section">
            <div className="topics-toc">
                {generateTocItems()}
                <div className="version-dropdown-toc-item">
                    <VersionDropdown showPicker={false}/>
                </div>
            </div>
            <div className="topics-container">
                <div className="topics-container-header">
                    <h2>{selectedCategory ? categoriesData[selectedCategory]['title_long'] : (selectedProduct ? categoriesData[productJSON[selectedProduct]['active_topic_categories'][0]]['title_long'] : 'All Documentation')}</h2>
                    <span className="version-info-message">
                        <i>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                            <rect width="16" height="16" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                            <path d="M8.5 11V7H6.5V8H7.5V11H6V12H10V11H8.5Z" fill="#0043CE"/>
                            <path d="M8 4C7.85167 4 7.70666 4.04399 7.58333 4.1264C7.45999 4.20881 7.36386 4.32595 7.30709 4.46299C7.25033 4.60004 7.23548 4.75084 7.26441 4.89632C7.29335 5.04181 7.36478 5.17544 7.46967 5.28033C7.57456 5.38522 7.7082 5.45665 7.85369 5.48559C7.99917 5.51453 8.14997 5.49968 8.28701 5.44291C8.42406 5.38615 8.54119 5.29002 8.6236 5.16668C8.70602 5.04334 8.75 4.89834 8.75 4.75C8.75 4.55109 8.67098 4.36033 8.53033 4.21967C8.38968 4.07902 8.19892 4 8 4Z" fill="#0043CE"/>
                            <path d="M8 15C6.61553 15 5.26216 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26216 15 6.61553 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z" fill="#0043CE"/>
                            </svg>
                        </i>
                        <span>For versions below 11.1.0, visit <a href="https://documentation.softwareag.com/" rel="noopener noreferrer">documentation.softwareag.com</a>.</span>
                    </span>
                    <div className="document-category-dropdown-container">
                        <DocumentCategoryDropdown 
                            labelText='Choose Document Category'
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            ALL_DOCUMENTS_KEY={ALL_DOCUMENTS_KEY}
                            selectedProduct={selectedProduct}
                        />
                    </div>
                    <div className="topic-searchbox-container">
                        <i>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.33496 12H5.33496C5.06974 12 4.81539 11.8946 4.62785 11.7071C4.44032 11.5196 4.33496 11.2652 4.33496 11V7.205L0.629961 3.5C0.442167 3.31332 0.336077 3.05979 0.334961 2.795V1C0.334961 0.734784 0.440318 0.48043 0.627854 0.292893C0.81539 0.105357 1.06974 0 1.33496 0H11.335C11.6002 0 11.8545 0.105357 12.0421 0.292893C12.2296 0.48043 12.335 0.734784 12.335 1V2.795C12.3338 3.05979 12.2278 3.31332 12.04 3.5L8.33496 7.205V11C8.33496 11.2652 8.2296 11.5196 8.04207 11.7071C7.85453 11.8946 7.60018 12 7.33496 12ZM1.33496 1V2.795L5.33496 6.795V11H7.33496V6.795L11.335 2.795V1H1.33496Z"/>
                            </svg>
                        </i>
                        <input 
                            id="topic-searchbox" 
                            type="text" 
                            placeholder="Filter"
                            value={searchValue} 
                            onChange={(event) => setSearchValue(event.target.value)}
                            autoComplete="off"
                        />

                        {searchValue && (
                            <button 
                                title='Clear'
                                className="clear-searchbox-button" 
                                onClick={() => setSearchValue('')} 
                                aria-label="Clear Search"
                            >
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.38381 5L10.5 0.883812L9.61619 0L5.5 4.11619L1.38394 0L0.5 0.883812L4.61619 5L0.5 9.11619L1.38394 10L5.5 5.88381L9.61619 10L10.5 9.11619L6.38381 5Z" fill="#525252"/>
                                </svg>
                            </button>
                        )}
                    </div>
                    <span className="topic-searchbox-result">
                    {
                        searchValue ?
                        <React.Fragment>
                            <span className="search-count">
                                {Object.keys(topicData).length} results
                            </span> 
                            &nbsp;for "{searchValue}"
                        </React.Fragment>
                       : ''
                    }
                    </span>
                </div>
                <div className="topics-list">
                    {generateTopicCardElts()}
                </div>
            </div>
        </section>
    )
}

export default ProductContent;