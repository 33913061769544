import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';

import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import ProductLandingPage from './Pages/ProductLandingPage/ProductLandingPage';
import SearchPage from './Pages/SearchPage/SearchPage';

import productJSON from './Data/products.json';

function App() {

  const [selectedCapability, setSelectedCapability] = useState('');

  const router = createBrowserRouter([
    { path:'/', element:<LandingPage selectedCapability={selectedCapability} setSelectedCapability={setSelectedCapability}/> },
    { path:'/search', element:<SearchPage/> },
    {
      path: '/on-premises/webmethods-adapter',
      loader: () => redirect('/on-premises/webmethods-adapters'), // Redirect the correct adapter link
    },
    {
      path: '/:deployment/:productName',
      element: <ProductLandingPage selectedCapability={selectedCapability}/>,
      loader: async ({ params }) => {
        const { deployment, productName } = params;
        const fullPath = `/${deployment}/${productName}`;
        const validPaths = (Object.keys(productJSON).map(productId => productJSON[productId].baseURL))
        
        if(!validPaths.includes(fullPath)) {
          return redirect('/');
        }
        return null;
      },
    },
    {
      path: '*',
      loader: () => redirect('/'), // Redirect to the root path for any undefined routes
    },
  ])

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
