import React from "react";
import { Link } from "react-router-dom";

import logoDark from '../../assets/images/./ibm_dark.svg';
import './NavBar.css';
import SearchBox from "../SearchBox/SearchBox";

const NavBar = (props) => {

    return(
        <nav id="main-nav">
            <style>
                {
                    `
                        /* Auto-complete suggestions styling */
                        table.gssb_c {
                            min-width: 160px !important;
                            width: 40vw !important;
                            max-width: 360px !important;
                            margin-left: -4px;
                        }

                        @media screen and (max-width: 470px) {
                              table.gssb_c {
                                width: 90% !important;
                                max-width: 360px !important;
                            }
                        }
                    `
                }
            </style>
            <Link id="logo-container" to="/">
                <img src={logoDark} alt="IBM webMethods Documentation"/>
            </Link>
            <div id="search-container">
                <div id="search-element">
                { props.noSearch ? 
                    <div id="gcse-searchbox"></div>
                    :
                    <SearchBox/>
                }
                </div>
            </div>
        </nav>
    )
}

export default NavBar;