import React from "react";

import productJSON from '../../Data/products.json';

import './ProductTitle.css';
import bannerImage from '../../assets/images/background.png'


const ProductTitle = ({ selectedProduct }) => {

    return(
        <section id="product-title-section" style={{backgroundImage:`url(${bannerImage})`}}>
            <h1 id="product-title">
                {selectedProduct in productJSON ? `${productJSON[selectedProduct].title_long}` : ''}
            </h1>
            <h3>
                { selectedProduct in productJSON && productJSON[selectedProduct].versions[0] ? `Version ${productJSON[selectedProduct].versions[0]}` : ''}
            </h3>
        </section>
    )
}

export default ProductTitle;